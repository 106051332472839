<template>
  <div class="box">
    <img class="logo" src="../../../public/loginLogo.png" />
    <h3>{{ $t('重置你的密码') }}</h3>
    <template v-if="!ticketOrNot && !phoneNumber">
      <span class="desc">{{ $t('请输入您的电子邮件地址或电话号码我们将向您发送重置密码的指示') }}</span>
      <div class="input-box">
        <label v-show="emailActive" :class="{ active: !emailActive }" :style="emailMsg ? 'color:#d00e17' : ''">{{emailPlaceholder}}</label>
        <input name="username" @focus="emailActive = true" @blur="emailBlur()" @keyup.enter="next()" v-model="userEmail"
          :placeholder="emailActive ? '' : emailPlaceholder" :style="emailMsg ? 'border: 1px solid #d00e17;' : ''" />
        <!-- <span class="error" v-if="emailMsg != ''"><i class="iconfont icon-error"></i>{{ emailMsg }}</span> -->
        <span class="error" v-if="emailRet === 5"><i class="iconfont icon-error"></i>{{ $t('电话号码不能为空') }}</span>
        <span class="error" v-if="emailRet === 4"><i class="iconfont icon-error"></i>{{ $t('请输入有效的号码') }}</span>
        <span class="error" v-if="emailRet === 3"><i class="iconfont icon-error"></i>{{ $t('填写格式有误') }}</span>
        <span class="error" v-if="emailRet === 2"><i class="iconfont icon-error"></i>{{ $t('用户不存在') }}</span>
        <span class="error" v-if="emailRet === 1"><i class="iconfont icon-error"></i>{{ $t('验证码已发送请勿重复获取') }}</span>
        <span class="error" v-if="emailRet === -1"><i class="iconfont icon-error"></i>{{ $t('短信发送失败') }}</span>
      </div>
      <button class="continue" @click="next()">{{ $t("确定") }}</button>
    </template>
    <template v-if="ticketOrNot">
      <span class="desc">{{ $t('请在下面输入新密码以更改密码') }}</span>
      <div class="input-box">
        <label class="pass-label" v-show="passActive" :class="{ active: !passActive }">{{ $t('新密码') }}</label>
        <input name="password" :type="showPassword ? 'text' : 'password'" @focus="passActive = true" @blur="passBlur()"
          v-model="newPassword" :placeholder="passActive ? '' : $t('新密码')" />
        <span @click="togglePassword()" v-if="!showPassword" class="glyphicon glyphicon-eye-open"></span>
        <span @click="togglePassword()" v-else class="glyphicon glyphicon-eye-close"></span>
        <span class="error" v-if="passMsg != ''"><i class="iconfont icon-error"></i>{{ passMsg }}</span>
      </div>
      <div class="input-box">
        <label class="repass-label" v-show="rePassActive" :class="{ active: !rePassActive }">{{ $t('重新输入新密码') }}</label>
        <input name="password" :type="showRePassword ? 'text' : 'password'" @focus="rePassActive = true"
          @blur="rePassBlur()" v-model="reNewPassword" :placeholder="rePassActive ? '' : $t('重新输入新密码')" />
        <span @click="toggleRePassword()" v-if="!showRePassword" class="glyphicon glyphicon-eye-open"></span>
        <span @click="toggleRePassword()" v-else class="glyphicon glyphicon-eye-close"></span>
        <span class="error" v-if="rePassMsg != ''"><i class="iconfont icon-error"></i>{{ rePassMsg }}</span>
      </div>
      <span class="toasts" :style="toastType == 'success' ? 'color:#10a37f' : 'color:red'" v-if="toast">{{ toast }} <span
          v-if="toastType == 'error'">( {{ countDown }}s )</span></span>
      <button class="continue" @click="reset()">{{ $t('重置密码') }}</button>
    </template>
    <template v-if="phoneNumber">
      <!-- 号码 -->
      <div class="input-box">
        <label v-show="emailActive" :class="{ active: !emailActive }" :style="emailMsg ? 'color:#d00e17' : ''">{{ (!emailPlaceholder ? '' : emailPlaceholder === 1 ? $t('邮箱') : $t('手机')) || $t('电话或邮箱') }}</label>
        <input name="username" @focus="emailActive = true" @blur="emailBlur()" @keyup.enter="next()" v-model="userEmail"
          :placeholder="emailActive ? '' : emailPlaceholder" :style="emailMsg ? 'border: 1px solid #d00e17;' : ''" />
        <span class="error" v-if="emailMsg != ''"><i class="iconfont icon-error"></i>{{ emailMsg }}</span>
      </div>
      <!-- 验证码 -->
      <div class="input-box">
        <label class="verify-label" v-show="validateActive" :class="{active:!validateActive}">{{ $t('验证码') }}</label>
        <input name="validate" @focus="validateActive = true" @blur="validateBlur()" v-model="validateCode"
          :placeholder="validateActive?'':this.$t('验证码')" :maxlength="sendTest === 6" />
        <span class="error" v-if="validateMsg != ''"><i class="iconfont icon-error"></i>{{validateMsg}}</span>
        <!-- 发送验证码 -->
        <button @click="sendEmail()" v-if="send_seconds == 0" class="send">{{ sendTest }}</button>
        <span class="email-timer" v-else>{{sendEmailTip}}</span>
      </div>
      <!-- 密码 -->
      <div class="input-box">
        <label class="pass-label" v-show="passActive" :class="{ active: !passActive }">{{ $t('新密码') }}</label>
        <input name="password" :type="showPassword ? 'text' : 'password'" @focus="passActive = true" @blur="passBlur()"
          v-model="newPassword" :placeholder="passActive ? '' : $t('新密码')" />
        <span @click="togglePassword()" v-if="!showPassword" class="glyphicon glyphicon-eye-open"></span>
        <span @click="togglePassword()" v-else class="glyphicon glyphicon-eye-close"></span>
        <span class="error" v-if="passMsg != ''"><i class="iconfont icon-error"></i>{{ passMsg }}</span>
      </div>
      <!-- 确认密码 -->
      <div class="input-box">
        <label class="repass-label" v-show="rePassActive" :class="{ active: !rePassActive }">{{ $t('重新输入新密码') }}</label>
        <input name="password" :type="showRePassword ? 'text' : 'password'" @focus="rePassActive = true"
          @blur="rePassBlur()" v-model="reNewPassword" :placeholder="rePassActive ? '' : $t('重新输入新密码')" />
        <span @click="toggleRePassword()" v-if="!showRePassword" class="glyphicon glyphicon-eye-open"></span>
        <span @click="toggleRePassword()" v-else class="glyphicon glyphicon-eye-close"></span>
        <span class="error" v-if="rePassMsg != ''"><i class="iconfont icon-error"></i>{{ rePassMsg }}</span>
      </div>
      <span class="toasts" :style="toastType == 'success' ? 'color:#10a37f' : 'color:red'" v-if="toast">{{ toast }} <span
          v-if="toastType == 'error'">( {{ countDown }}s )</span></span>
      <button class="continue" @click="resetPhone()">{{ $t('重置密码') }}</button>
    </template>
  </div>
</template>

<script>
import {
  notifyPassword,
  queryTicket,
  resetPassword,
  sendPhone,
  checkUser
} from '../../config/api.js'
import {phoneReg,emailReg} from '../../utils/regix.js'
export default {
  name: 'reset',
  data() {
    return {
      emailActive: false,
      emailMsg: '',
      emailRet: null,
      emailPlaceholder: this.$t('电话或邮箱'),
      userEmail: '',
      ticketOrNot: false,
      phoneNumber: false, // 手机重置密码时，需要后续显示的组件
      passMsg: '',
      rePassMsg: '',
      passActive: false,
      rePassActive: false,
      newPassword: '',
      reNewPassword: '',
      showPassword: false,
      showRePassword: false,
      ticket: null,
      toast: '',
      toastType: null,
      countDown: 0,
      tsInterval: null,
      send_seconds: 0,
      sendTest: this.$t('获取验证码'),
      sendEmailTip: '',
      validateActive: false,
      validateCode: '',
      validateMsg: '',
    }
  },
  mounted() {
    this.userEmail = this.$route.query.email
    console.log(this.userEmail)
    const phoneRegex = /^\d{11}$/;
    if (phoneRegex.test(this.userEmail)) {
      this.emailPlaceholder = this.$t('手机');
    }
    else {
      this.emailPlaceholder = this.$t('邮箱');
      this.ticket = this.$route.query.ticket
      let that = this
      if (this.ticket !== undefined) {
        queryTicket({
          "email": this.userEmail,
          "ticket": this.ticket
        }).then(res => {
          that.ticketOrNot = res.data["ret"]
        })
      }
    }
  },
  methods: {
    emailBlur() {
      if (this.userEmail == '') {
        this.emailActive = false
      }
    },
    passBlur() {
      if (this.newPassword == '') {
        this.passActive = false
      }
    },
    rePassBlur() {
      if (this.reNewPassword == '') {
        this.rePassActive = false
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    toggleRePassword() {
      this.showRePassword = !this.showRePassword;
    },
    validateBlur() {
      if (this.validateCode == '') {
        this.validateActive = false
      }
    },
    next() { // 第一个确定按钮
      let that = this
      if (!that.userEmail) { that.emailRet = 5; that.emailMsg = that.$t('电话号码不能为空'); return; }
      if (this.emailPlaceholder === this.$t('邮箱')) {
        notifyPassword({
          "username": this.userEmail
        }).then(res => {
          if (res.data["emailMsg"] == 'Invalid email address') {
            return that.emailMsg = this.$t('无效的电子邮件地址');
          } else {
            that.emailMsg = ''
            that.$router.replace({
              name: 'resend',
              params: {
                'email': that.userEmail
              }
            })
          }
        })
      }
      if (this.emailPlaceholder === this.$t('手机')) {
        checkUser({
          "username": this.userEmail
        }).then(res => {
          /**regMethod为注册格式 -1为不合规 1为邮箱 2为手机  */
          if (res.data["regMethod"] === -1) { that.emailRet = res.data["ret"];that.emailMsg = that.$t('短信发送失败'); return; }
          if (res.data["regMethod"] === 1) { that.emailPlaceholder = res.data["regMethod"] }
          if (res.data["regMethod"] === 2) { that.emailPlaceholder = res.data["regMethod"] }
          that.checkUserOrNot = res.data["checkOrNot"]
          if (!that.checkUserOrNot) {
            that.emailMsg = this.$t('用户不存在');
            this.emailRet = 2
            that.emailActive = true
          }else {
            that.emailMsg = ''
            that.emailRet = null
            that.ticketOrNot = false
            that.phoneNumber = true
          }
        })
      }
      if (this.emailPlaceholder === this.$t('电话或邮箱')) {
        that.emailRet = 3; 
        return that.emailMsg = this.$t('填写格式有误');
      }
    },
    // 邮箱用户重置密码
    reset() {
      let that = this
      this.passMsg = ''
      this.rePassMsg = ''
      let params = {
          "ticket": this.ticket,
          "newPassword": this.newPassword,
          "reNewPassword": this.reNewPassword
        }
      // console.log(params)
      resetPassword(params).then(res => {
        if (res.data["passMsg"] === 'The password must contain a maximum of 8 characters') {
          return that.passMsg = that.$t('密码长度不超过8位');
        } if (res.data["passMsg"] === 'The password cannot be a pure number') {
          return that.passMsg = that.$t('密码不能为纯数字');
        } if (res.data["passMsg"] == 'The password must contain at least two combinations of letters and numbers, and not less than 8 characters') {
          return	that.passMsg = that.$t('密码至少包含字母和数字的两种组合且不小于8个字符')
        } if (res.data["passMsg"] == 'The password cannot contain spaces') {
					return that.passMsg = this.$t('密码不能包含空格')
        } else if (res.data["rePassMsg"] === 'The two password inputs are inconsistent') {
          return that.rePassMsg = that.$t('两次密码输入不一致');
        } else if (res.data["toast"] === 'The page has expired') {
          return that.toast = that.$t('页面已过期');
        } else if (res.data["toast"] === 'modified successfully！') {
          that.toast = that.$t('修改成功');
          that.toastType = res.data["type"]
          that.countDown = 3
          that.tsInterval = setInterval(function () {
            if (that.countDown > 0) {
              that.countDown -= 1
            } else {
              clearInterval(that.tsInterval)
              localStorage.removeItem("userData")
              that.$router.push({
                name: 'login'
              })
            }
          }, 1000)
          return;
        } else if (res.data["toast"] !== '') {
          that.toast = res.data["toast"]
          that.toastType = res.data["type"]
          if (that.toastType === "error") {
            if (res.data["toast"] === 'Please enter the correct mobile phone verification code') {
              return that.validateMsg = that.$t('请输入正确的手机验证码')
            } else {
              
            }
          }
        }
      })
    },
    resetPhone(){ // 手机用户重置密码
      let that = this
      this.passMsg = ''
      this.rePassMsg = ''
      let params = {}
      if (!that.userEmail) { that.emailRet = 5; that.emailMsg = that.$t('电话号码不能为空'); return; }
      if (that.ticket) {
        params = {
          "ticket": this.ticket,
          "newPassword": this.newPassword,
          "reNewPassword": this.reNewPassword
        }
      } else {
        params = {
          "phone": this.userEmail,
          "code": this.validateCode,
          "newPassword": this.newPassword,
          "reNewPassword": this.reNewPassword
        }
      }
      // console.log(params)
      resetPassword(params).then(res => {
        if (res.data["passMsg"] === 'The password must contain a maximum of 8 characters') {
          return that.passMsg = that.$t('密码长度不超过8位');
        } if (res.data["passMsg"] === 'The password cannot be a pure number') {
          return that.passMsg = that.$t('密码不能为纯数字');
        } if (res.data["passMsg"] == 'The password must contain at least two combinations of letters and numbers, and not less than 8 characters') {
          return	that.passMsg = that.$t('密码至少包含字母和数字的两种组合且不小于8个字符')
        } if (res.data["passMsg"] == 'The password cannot contain spaces') {
					return that.passMsg = this.$t('密码不能包含空格')
        } else if (res.data["rePassMsg"] === 'The two password inputs are inconsistent') {
          return that.rePassMsg = that.$t('两次密码输入不一致');
        } else if (res.data["toast"] === 'The page has expired') {
          return that.toast = that.$t('页面已过期');
        } else if (res.data["toast"] === 'modified successfully！') {
          that.toast = that.$t('修改成功');
          that.toastType = res.data["type"]
          that.countDown = 3
          that.tsInterval = setInterval(function () {
            if (that.countDown > 0) {
              that.countDown -= 1
            } else {
              clearInterval(that.tsInterval)
              localStorage.removeItem("userData")
              that.$router.push({
                name: 'login'
              })
            }
          }, 1000)
          return;
        } else if (res.data["toast"] !== '') {
          that.toast = res.data["toast"]
          that.toastType = res.data["type"]
          if (that.toastType === "error") {
            if (res.data["toast"] === 'Please enter the correct mobile phone verification code') {
              return that.validateMsg = that.$t('请输入正确的手机验证码')
            } else {
              
            }
          }
        }
      })
    },
    sendEmail() { // 验证码获取
      this.emailRet = null
      const phoneRegex = /^\d{11}$/;
      if (!this.userEmail) {this.emailRet = 5;return (this.emailMsg = this.$t("电话号码不能为空"));}
      if (!phoneRegex.test(this.userEmail)) {this.emailRet = 4; return (this.emailMsg = this.$t("请输入有效的号码"));}
      this.emailMsg = "";
      let that = this;

      that.send_seconds = 59;
      that.sendEmailTip = `${that.$t("在")} ${that.send_seconds} ${that.$t(
        "秒后重试"
      )}`;
      that.sendEmailTimer = setInterval(function () {
        if (that.send_seconds > 0) {
          that.send_seconds -= 1;
          that.sendEmailTip = `${that.$t("在")} ${that.send_seconds} ${that.$t(
            "秒后重试"
          )}`;
        } else {
          clearInterval(that.sendEmailTimer);
        }
      }, 1000);
      
      sendPhone({
        phone: that.userEmail
      }).then(res => {
        if (res.data["ret"] === -1) { this.emailRet = res.data["ret"]; return (that.emailMsg = this.$t("短信发送失败")); }
        if (res.data["ret"] === 1) { this.emailRet = res.data["ret"]; return (that.emailMsg = this.$t("验证码已发送请勿重复获取")); }
      });
    },
  },
  watch: {
    userEmail(newVall) {
      const phoneRegex = /^\d{11}$/;
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (phoneRegex.test(newVall)) return this.emailPlaceholder = this.$t('手机')
      if (emailRegex.test(newVall))  return this.emailPlaceholder = this.$t('邮箱')
      else return this.emailPlaceholder = this.$t('电话或邮箱')
    },
    emailPlaceholder(val){
      console.log(val)
    }
  },
  // computed:{
  //   numType(){
  //     return 
  //   }
  // }
}
</script>

<style lang="less" scoped>

  .box {
    width: 100%;
    margin: 0;
    text-align: center;
    padding-top: 8%;

    .logo {
      width: 100px;
      height: 100px;
      display: block;
      margin: 1% auto 4rem auto;
      border-radius: 12%;
			border: 3px solid #10a37f;
    }

    h3 {
      margin-bottom: 2rem;
    }

    .desc {
      width: 25%;
      display: inline-block;
    }

    .input-box {
      width: 25%;
      margin: 3rem auto;
      position: relative;
      text-align: left;

      label {
        display: block;
        color: #10a37f;
        position: absolute;
        margin-left: 20%;
        margin-top: 1.5rem;
        padding: 0 .5rem;
        position: absolute;
        // top: -50%;
        transform: translate(-50%,-150%);
        transition: all .3s ease-in-out;
        transition-delay: .1s;
		    background-color: white;
      }


      label.active {
        top: -2rem;
        left: 2%;
        transform: translate(0, -20%) scale(0.8);
      }

      .repass-label {
        margin-left: 30%;
      }

      input {
        height: 52px;
        width:100%;
        border: 1px solid #10a37f;
        border-radius: 3px;
        padding: 0 10px;
		font-size: 14px;
      }

      input:focus {
        outline: none;
        border: 1px solid #10a37f;
      }

      input::-webkit-input-placeholder {
        margin-left: 10%;
        text-indent: 20px;
      }

      .error {
        color: #d00e17;
        font-size: 12px;

        .icon-error {
          vertical-align: middle;
          margin-right: .5rem;
        }
      }

      .glyphicon-eye-open,
      .glyphicon-eye-close {
        position: absolute;
        right: 0;
        top: 2rem;
        right: 5%;
      }

      .glyphicon-eye-open:hover,
      .glyphicon-eye-close:hover {
        cursor: pointer;
      }
	  
      .send {
        background-color: #10a37f;
        color: white;
        outline: none;
        border: none;
        border-radius: 3px;
        position: absolute;
        right: 8px;
        top: 8px;
        height: 36px;
      }
      .email-timer {
        background-color: white;
        color: #10a37f;
        border: 1px solid #10a37f;
        border-radius: 3px;
        position: absolute;
        padding: .5rem;
        right: 12px;
        top: 12px;
        font-size: 12px;
      }
    }

    .toasts {
      display: block;
      margin-bottom: 3rem;
    }

    .continue {
      background-color: #10a37f;
      color: white;
      border: 0px;
      outline: none;
      border-radius: 3px;
      height: 52px;
      width: 25%;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: 768px) {

    .box {
      .input-box,
      .continue,.desc {
        width: 80% !important;
      }
    }


  }
</style>
